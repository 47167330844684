:root {
  --currentPage-color-dark: #FFF;
  --currentPage-color-light: #000;

  --name-logo-color-light: #000;
  --name-logo-color-dark: #FFF;
}

.nav {
  display: inline-grid;
  height: fit-content;
  overflow-y: hidden;

  margin-bottom: 1vh;

  position: fixed;
  left: 1vw;
}

.nav-logo {
  width: 2.5vw;
  height: 2.5vw;
  margin-right: 0.5vw;
}

.diffrentPage  {
  color: #468189;
}


@media (prefers-color-scheme: light) {
  .currentPage {
    color: var(--currentPage-color-light);
  }

  .name-logo {
    color: var(--name-logo-color-light);
  }
}

@media (prefers-color-scheme: dark) {
  .currentPage {
    color: var(--currentPage-color-dark);
  }

  .name-logo {
    color: var(--name-logo-color-dark);
  }
}

