:root {
  --background-color-dark: #031926;
  --background-color-light: #F4E9CD;

  --text-main-color-dark: #2f6c75;
  --text-main-color-light: #0d2c7a;

  --text-secondary-color-dark: #F4E9CD;
  --text-secondary-color-light: #66A5AD;

  font-size: 1.3rem;
  font-family: Bebas Neue; 
  font-weight: 400; 

  text-transform: uppercase;

  text-align: center;
  align-items: center;

  padding-left: 6.25%;
  padding-right: 6.25%;
  width: 87.5%;
}

.main {
  margin-top: 10vh;
}

@media (prefers-color-scheme: light) {
  :root {
    background-color: var(--background-color-light);
    color: var(--text-main-color-light);
  }

  .secondary-text {
    color: var(--text-secondary-color-light);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    background-color: var(--background-color-dark);
    color: var(--text-main-color-dark);
  }

  .secondary-text {
    color: var(--text-secondary-color-dark);
  }
}

* {
  overflow-x: hidden;
}

.hidden-left {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.hidden-right {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(100%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0%);
}

.gridItem:nth-child(3n+1) {
  transition-delay: 0.1s;
}

.gridItem:nth-child(3n+2) {
  transition-delay: 0.2s;
}

.gridItem:nth-child(3n+3) {
  transition-delay: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .hidden-left {
    transition: none;
  }

  .hidden-right {
    transition: none;
  }
}
